// window.$ = window.jQuery = require('jquery');
// import $ from 'jquery';
// window.$ = $;



// require('./bootstrap');
import './bootstrap';

// import('moment');
// window.moment = require('moment/moment');
import 'bootstrap4-toggle';
import 'bootstrap-3-typeahead';
//prismjs
// require('prismjs');
import Prism from 'prismjs';
// import 'prismjs/themes/prism-okaidia.css'
// import 'prismjs/components/prism-handlebars.min.js'
// import 'prismjs/components/prism-lua.min.js'
import "prismjs/components/prism-markup-templating"
import "prismjs/components/prism-markup"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-c"
import "prismjs/components/prism-cpp"
import "prismjs/components/prism-csharp"
import "prismjs/components/prism-docker"
import "prismjs/components/prism-java"
import "prismjs/components/prism-js-templates"
import "prismjs/components/prism-typescript"
import "prismjs/components/prism-coffeescript"
import "prismjs/components/prism-diff"
import "prismjs/components/prism-git"
import "prismjs/components/prism-go"
import "prismjs/components/prism-graphql"
import "prismjs/components/prism-handlebars"
import "prismjs/components/prism-json"
import "prismjs/components/prism-less"
import "prismjs/components/prism-makefile"
import "prismjs/components/prism-markdown"
import "prismjs/components/prism-objectivec"
import "prismjs/components/prism-ocaml"
import "prismjs/components/prism-python"
import "prismjs/components/prism-reason"
import "prismjs/components/prism-rust"
import "prismjs/components/prism-sass"
import "prismjs/components/prism-scss"
import "prismjs/components/prism-solidity"
import "prismjs/components/prism-sql"
import "prismjs/components/prism-stylus"
import "prismjs/components/prism-swift"
import "prismjs/components/prism-wasm"
import "prismjs/components/prism-yaml"
import "prismjs/components/prism-php"
// const getLoader = require('prismjs/dependencies');
// const components = require('prismjs/components');
// const componentsToLoad = ['markup', 'css', 'php', 'python', 'bash'];
// const loadedComponents = ['clike', 'javascript'];
// const loader = getLoader(components, componentsToLoad, loadedComponents);
// loader.load(id => {
//     require(`prismjs/components/prism-${id}.min.js`);
// });
//custom
import './keyboard-pagination';
import './helpers';

// window.bytesToSize = function bytesToSize(bytes) {
//     var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
//     if (bytes == 0) return '0 Byte';
//     var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
//     return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
// };

keyboardPagination('.pagination', {
    num: '.page-item',
    numCurrent: '.active'
});
$(window).on('shown.bs.modal', function () {
    keyboardPagination(false);
});
$(window).on('hidden.bs.modal', function () {
    keyboardPagination('.pagination', {
        num: '.page-item',
        numCurrent: '.active'
    });
});