// _ = require('lodash');
import _ from 'lodash';
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import 'overlayscrollbars';
import { OverlayScrollbars } from 'overlayscrollbars';
import "bootstrap";
import $ from 'jquery';
import * as Popper from '@popperjs/core'
import moment from 'moment';
import 'admin-lte';
// import '../../vendor/almasaeed2010/adminlte/dist/js/adminlte.js';
import axios from 'axios';
import Inputmask from "inputmask";
import 'select2'; // Modifies the global jQuery object.
// $('select').select2();

if (typeof (window) !== 'undefined') {
    window._ = _;
    // global.$ = global.jQuery = $;
    window.$ = window.jQuery = $;
    window.Popper = Popper;
    window.moment = moment;
    window.Inputmask = Inputmask;
    window.axios = axios;
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
}
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
