window.bytesToSize = function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};
window.removeTags = function removeTags(str) {
    if ((str === null) || (str === ''))
        return false;
    else
        str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
};
window.callAjax = function callAjax(data = null) {
    let result = null
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: $('meta[name="api-url"]').attr('content'),
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=UTF-8',
        async: false,
    });
    $.ajax({
        data: JSON.stringify(data),
    }).done(function (response) {
        try {
            result = JSON.parse(response)
        } catch {
            result = response
        }
    }).fail(function (jqXHR, error) {
        console.log(error)
        if (jqXHR.status == 419) {
            $.ajax({
                type: 'POST',
                url: '/logout',
                complete: function (xhr, status) {
                    location.reload();
                }
            });
        }
    })
    return result
};
window.callAjaxCustom = function callAjaxCustom(url, type, data = null) {
    let result = null
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: url,
        type: type,
        dataType: 'json',
        contentType: 'application/json; charset=UTF-8',
        async: false,
    });
    $.ajax({
        data: JSON.stringify(data),
    }).done(function (response) {
        try {
            result = JSON.parse(response)
        } catch {
            result = response
        }
    }).fail(function (jqXHR, error) {
        console.log(error)
        if (jqXHR.status == 419) {
            $.ajax({
                type: 'POST',
                url: '/logout',
                complete: function (xhr, status) {
                    location.reload();
                }
            });
        }
    })
    return result
};

window.nl2br = function nl2br(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

window.humanFileSize = function humanFileSize(size) {
    if (size == 0) return 0;
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
};